.dx-wf-graph {
    background-color: white; 
    max-height:600px;
    min-height:500px;
    overflow: auto;

    svg {
        min-height:500px;
    }
}

.dx-cms-workflow-form-tab-steps .dx-wf-graph {
    max-height:none;  

    svg {
        height:auto;
    }  
}

.dx-wf-toolbar {
    height:20px; 
    background-color: #e1e5ec;
}

.dx-cms-workflow-form-body .tab-pane{
    margin-top: -10px;
}