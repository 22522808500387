.dx-grid-outer-div {
	min-height: 250px;
}

.dx-grid-outer-div div.checker {
	margin-top: 0px !important;
}

.dx-marked-count-lbl {
	font-weight: bold;
}

#td_data .dx-grid-outer-div {
	overflow-y: auto;
	overflow-x: auto;
	margin-bottom: 20px;
}

.modal-scrollable {
	overflow: hidden !important;
}

/* view edit form */

.dx-view-edit-form .dx-used .dx-cms-field-remove {
	display: block;
}

.dx-view-edit-form .dx-available .dx-cms-field-remove {
	display: none;
}

.dx-view-edit-form .dx-used .dx-cms-field-add {
	display: none;
}

.dx-view-edit-form .dx-available .dx-cms-field-add {
	display: block;
}

.dx-view-edit-form .dx-used .dx-icon-filter {
	display: inline;
}

.dx-view-edit-form .dx-available .dx-icon-filter {
	display: none;
}

.dx-view-edit-form .dx-used .dd-item[data-is-hidden="1"] .dx-icon-hidden {
	display: inline;
}

.dx-view-edit-form .dx-used .dd-item[data-is-hidden="0"] .dx-icon-hidden {
	display: none;
}

.dx-view-edit-form .dx-used .dd-item:not([data-operation-id="0"]) .dx-icon-filter {
	display: inline;
}

.dx-view-edit-form .dx-used .dd-item[data-operation-id="0"] .dx-icon-filter {
	display: none;
}

.dx-view-edit-form .dx-available .dx-icon-hidden {
	display: none;
}

.dx-view-edit-form .dx-icon-hidden, .dx-view-edit-form .dx-icon-filter {
	color: #acb5c3;
}

.dx-view-edit-form .dx-fields-body {
	overflow-y: auto;
	overflow-x: hidden;
}

#view_edit_popup .modal-footer {
	border-top: 1px solid silver;
}

@media screen and (min-height: 200px) {
	.dx-view-edit-form .dx-fields-body {
		height: 240px;
	}
}

@media screen and (min-height: 800px) {
	.dx-view-edit-form .dx-fields-body {
		height: 440px;
	}
}

.dx-view-popup-footer {
	border-top: 1px #e5e5e5 solid !important;
}

.dx-view-edit-form .portlet-title .actions input {
	color: black;
	padding-left: 5px;
	width: 100px;
}

.dx-view-edit-form .dx-used .dx-fld-title {
	cursor: pointer;
}

.dx-view-edit-form .dx-available .dx-fld-title:hover {
	color: #333 !important;
}

.dx-view-edit-form .dx-available .dd3-content:hover {
	color: #333 !important;
}

.modal-open.page-overflow .page-container {
	overflow-y: auto !important;
}

a.header-filter:hover {
	color: #949090;
	border: 1px solid #949090;
}

a.header-filter {
	color: silver; 
	float:left; 
	width: 20px; 	
	padding-left: 8px; 
	margin-right: 4px;
	border: 1px solid silver;
	height: 20px;
}

.dx-dropdown-content {
	display: none;
	position: absolute;
	background-color: #f9f9f9;
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	padding: 12px 16px;
	z-index: 1;
}

.dx-menu-item {
	margin-bottom: 6px;
}

.floatThead-wrapper {
	overflow: hidden;
}

.dx-field-btn i {
	color: #337ab7;
}

.dx-field-btn:hover {
	background-color: #e6dede;
}

#dx-wrap {
	height: 100%;
}

#td_data {
	height: 100%;
}

.dx-block-container-view {
	height: 100%;
}

.dx-block-container-view > .portlet {
	height: 100%;
}

.dx-block-container-view > .portlet > .portlet-body {
	height: 100%;
}

.dx-grid-container {
	position: relative;
	/*height: calc(100% - 49px - 22px - 20px);*/
	background-color: white;
	margin-bottom: 20px;
	overflow: hidden;
}

.dx-grid-inner-container {
	overflow-x: auto !important;
	overflow-y: auto !important;
	width: 100%;
	height: 100%;
	border-top: 1px solid #e7ecf1;
	border-bottom: 1px solid #e7ecf1;
}

.dx-grid-table {
	width: 100%;
	margin: 0 !important;
}

.dx-horizontal-menu-ui .dx-grid-table thead {
	position: relative;
	z-index: 10000;
}

.dx-grid-table thead tr > * {
	background-color: #e7ecf1;
}

.dx-grid-table thead th, .dx-grid-table thead td {
	padding: 0 !important;
	border: 0 none!important;
}

.dx-grid-table thead th div, .dx-grid-table thead td div {
	position: relative;
	z-index: 9990;
	top: -1px;
	white-space: nowrap;
	background-color: #e7ecf1;
	height: 36px;
}

.dx-grid-table thead th div {
	top: -2px;
	line-height: 20px;
	padding: 8px;
}

.dx-grid-table thead td div {
	padding: 4px;
	line-height: 28px;
}

.dx-divider {
    border-top: 1px #f1f3f6 solid;
    height: 8px;
    margin-left: -15px;
    margin-right: -15px;
}

/* Dropdown field buttons */
.dx-rel-id-view-btn {
    border: 1px solid #c2cad8!important;
    margin-left: -2px!important;
}

.dx-top-right-menu .dropdown-submenu .dropdown-menu {
	left: auto;
	right: 100%;
}

.btn.btn-default.dx-status-left {
	background-color: #e5e5e5 !important;
	border: 0 none !important;
}

.btn.btn-default.dx-status-active {
	background-color: #26c281 !important;
	border: 0 none !important;
}

.btn.btn-default.dx-status-potential {
	background-color: #f7ca18 !important;
	border: 0 none !important;
}

.dx-overflow-cell {
    max-width: 500px;
    overflow: hidden; 
    white-space: nowrap; 
    text-overflow: ellipsis;
}

/* Adjust mobile view and small desktops */
@media only screen and (max-width: 1000px) {
	.dx_form_btns_left button {
		margin-top: 10px;
	}

	.dx-grid-left-btns .dx-datetime {
		margin-top: 10px;
	}

	.dx-grid-left-btns .dx-datetime button{
		margin-top: 0px;
	}
}

@media only screen and (max-width: 768px) {
	.dx-paginator-butons {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.dx-grid-left-btns button {
		margin-top: 10px;
	}

	.dx-grid-left-btns .dx-datetime button{
		margin-top: 0px;
	}

	.dx-grid-left-btns .dx-datetime {
		margin-top: 10px;
	}

	.dx-register-tools {
		float: right; /* In order to display popup menu */
	}

	#dx_tasks_count_li {
		display: none; /* ToDo: we stil need to display counters somehow in mobile too */
	}

	.dx-chat-notif-dropdown {
		display: none;
	}
}

/* Templates UI */
.dx-templ-row {            
	color: black;
    margin: 20px;
    padding: 10px;
    border-left: 5px solid #c1c1c1;
    background-color: #e4e8e7;
}   

.dx-templ-title {
	font-weight: bold;
}

/* Modal body height */
.modal-body {
    overflow-y: auto;
}

/* Table style for details display in error message box */
.dx-table-form {
	border: 1px solid #ddd;
	text-align: left;
	border-collapse: collapse;
	width: 100%;
}

.dx-table-form td {
	padding: 15px;
	border: 1px solid #ddd;
	text-align: left;
}

.dx-table-form th {
	background-color: #8c5053;
	padding: 15px;
	border: 1px solid #c1c1c1;
	text-align: left;
}

/* hide all element glyphicons */
.hide-glyphicons .glyphicon {
	display: none !important;
}

/* maximize popup */
.dx-modal-fullscreen .modal-dialog {
	width: 100%!important;
	height: 100%!important;
	margin: 0!important;
	padding: 0!important;
  }
  
.dx-modal-fullscreen .modal-content {
	height: auto!important;
	min-height: 100%!important;
	border-radius: 0!important;
  }

/* Simple table with scrollbars - dont forget to set tbody height with jQuery */
.dx-table-scroll tbody {
    display:block;
    height:400px;
	overflow-x:auto;
	overflow-y:scroll;
}
.dx-table-scroll thead, .dx-table-scroll tbody tr {
    display:table;
    width:100%;
    table-layout:fixed;
}
.dx-table-scroll thead {
    width: calc( 100% - 17px )/* scrollbar is average 1em/16px width, remove it from thead width */
}

.dx-table-scroll-horizontal {
	display:block;    
	overflow-x:auto;
	overflow-y:auto;
}

.dx-table-scroll-horizontal tbody{
	height:400px;
}

/* Larger popup modal width on large screens */
@media screen and (min-width: 1920px) {
	.modal-lg {
		width: 1600px;
	}
}

@media screen and (min-width: 1360px) {
	.modal-lg {
		width: 1100px;
	}
}

@media screen and (min-width: 1600px) {
	.modal-lg {
		width: 1400px;
	}
}

/* For related documents component */
.dx-tr-doc-divider, .dx-tr-doc-divider:hover {
	background-color: #676161!important; 
	color: white!important;
 }

/* For address form */
.dx-address-labels {
	margin: 0 auto;
}

.dx-address-labels label{
	padding-left: 5px;
	padding-right: 5px;
}

.pointer {
	cursor: pointer;
}

.tooltipster-content {	
	overflow-x: hidden!important;	
}

.page-portlet-fullscreen .modal-backdrop {
	z-index: 10070 !important;
}

.page-portlet-fullscreen .modal-scrollable {
	z-index: 10071 !important;
}

.page-quick-sidebar-wrapper.dx-employees-quick {
	z-index: 10011 !important;
}

.select2-container {
	z-index: 10001 !important;
}

.modal-scrollable .select2-container {
	z-index: 10051 !important;
}

body > .select2-container {
	z-index: 10071 !important;
}

