.dx-block-item-comments-avatar {
  object-fit: cover;
}
.dx-block-item-comments-avatar-sm {
  height: 20px;
  width: 20px;
  border-radius: 50%!important;
}
#dx-block-item-comments-panel .dx-block-item-comments-chat-form {
  margin-bottom: 30px;
  margin-top: 0px;
}
#dx-block-item-comments-panel .dx-block-item-comments-modified {
  font-style: italic;
}
.dx-block-item-comments-btns {
  float: right;
  width: 38px;
  padding-left: 3px;
}
.dx-block-item-comments-inputs {
  width: auto;
  overflow: hidden;
}
.dx-block-item-comments-inputs .dx-block-item-comments-input-text {
  max-height: 20vh;
  height: 68px;
}
.dx-block-item-comments-author {
  font-size: 10px;
  color: gray;
}
.message span.dx-block-item-comments-author {
  font-size: 10px;
  color: gray;
}


.dx-form-chat-avatar {
  object-fit: cover;
}
.dx-form-chat-avatar-sm {
  height: 20px;
  width: 20px;
  border-radius: 50%!important;
}
.dx-form-chat-user-list-row {
  padding: 5px;
  border-bottom: 1px solid lightgray;
}
.dx-chat-loader {
  border: 5px solid #f3f3f3;
  /* Light grey */
  border-top: 5px solid #3498db;
  /* Blue */
  border-bottom: 5px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: dx-spin 2s linear infinite;
}
@keyframes dx-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.dx-form-chat-panel .popover-content {
  overflow-y: scroll;
  height: 40vh;
}
.dx-form-chat-user-add-modal .dx-form-chat-user-field {
  width: 100%;
}
.dx-form-chat-user-add-modal .dx-form-chat-input-save-user-title {
  width: 100%;
}
.dx-form-chat-panel {
  position: fixed;
  bottom: 0;
  right: 0;
  display: none;
  background-color: white;
  padding: 0;
  border: solid 1px gray;
}
.dx-form-chat-panel .portlet {
  margin-bottom: 0;
}
.dx-form-chat-panel .portlet .portlet-body {
  height: auto;
}
.dx-form-chat-panel .portlet .portlet-body .dx-form-chat-content-container {
  overflow-y: scroll;
  height: 40vh;
}
.dx-form-chat-panel .portlet .portlet-body .dx-form-chat-msg-body {
  overflow: hidden;
  word-wrap: break-word;
}
.dx-form-chat-panel .portlet .portlet-body .dx-form-chat-progress {
  display: none;
}
.dx-form-chat-panel .portlet .portlet-body .dx-form-chat-progress .progress {
  padding-left: 5px;
  padding-right: 5px;
}
.dx-form-chat-panel .portlet .portlet-body .dx-form-chat-form-btns {
  float: right;
  width: 38px;
  padding-left: 3px;
}
.dx-form-chat-panel .portlet .portlet-body .dx-form-chat-form-btns .dx-form-chat-file-input {
  display: none;
}
.dx-form-chat-panel .portlet .portlet-body .dx-form-chat-form-inputs {
  width: auto;
  overflow: hidden;
}
.dx-form-chat-panel .portlet .portlet-body .dx-form-chat-form-inputs .dx-form-chat-input-text {
  max-height: 20vh;
  height: 68px;
}

/*!
 * Timepicker Component for Twitter Bootstrap
 *
 * Copyright 2013 Joris de Wit
 *
 * Contributors https://github.com/jdewit/bootstrap-timepicker/graphs/contributors
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
.bootstrap-timepicker {
  position: relative;
}
.bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu {
  left: auto;
  right: 0;
}
.bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu:before {
  left: auto;
  right: 12px;
}
.bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu:after {
  left: auto;
  right: 13px;
}
.bootstrap-timepicker .input-group-addon {
  cursor: pointer;
}
.bootstrap-timepicker .input-group-addon i {
  display: inline-block;
  width: 16px;
  height: 16px;
}
.bootstrap-timepicker-widget.dropdown-menu {
  padding: 4px;
}
.bootstrap-timepicker-widget.dropdown-menu.open {
  display: inline-block;
}
.bootstrap-timepicker-widget.dropdown-menu:before {
  border-bottom: 7px solid rgba(0, 0, 0, 0.2);
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
}
.bootstrap-timepicker-widget.dropdown-menu:after {
  border-bottom: 6px solid #FFFFFF;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
}
.bootstrap-timepicker-widget.timepicker-orient-left:before {
  left: 6px;
}
.bootstrap-timepicker-widget.timepicker-orient-left:after {
  left: 7px;
}
.bootstrap-timepicker-widget.timepicker-orient-right:before {
  right: 6px;
}
.bootstrap-timepicker-widget.timepicker-orient-right:after {
  right: 7px;
}
.bootstrap-timepicker-widget.timepicker-orient-top:before {
  top: -7px;
}
.bootstrap-timepicker-widget.timepicker-orient-top:after {
  top: -6px;
}
.bootstrap-timepicker-widget.timepicker-orient-bottom:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid #999;
}
.bootstrap-timepicker-widget.timepicker-orient-bottom:after {
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid #ffffff;
}
.bootstrap-timepicker-widget a.btn,
.bootstrap-timepicker-widget input {
  border-radius: 4px;
}
.bootstrap-timepicker-widget table {
  width: 100%;
  margin: 0;
}
.bootstrap-timepicker-widget table td {
  text-align: center;
  height: 30px;
  margin: 0;
  padding: 2px;
}
.bootstrap-timepicker-widget table td:not(.separator) {
  min-width: 30px;
}
.bootstrap-timepicker-widget table td span {
  width: 100%;
}
.bootstrap-timepicker-widget table td a {
  border: 1px transparent solid;
  width: 100%;
  display: inline-block;
  margin: 0;
  padding: 8px 0;
  outline: 0;
  color: #333;
}
.bootstrap-timepicker-widget table td a:hover {
  text-decoration: none;
  background-color: #eee;
  border-radius: 4px;
  border-color: #ddd;
}
.bootstrap-timepicker-widget table td a i {
  margin-top: 2px;
  font-size: 18px;
}
.bootstrap-timepicker-widget table td input {
  width: 25px;
  margin: 0;
  text-align: center;
}
.bootstrap-timepicker-widget .modal-content {
  padding: 4px;
}
@media (min-width: 767px) {
  .bootstrap-timepicker-widget.modal {
    width: 200px;
    margin-left: -100px;
  }
}
@media (max-width: 767px) {
  .bootstrap-timepicker {
    width: 100%;
  }
  .bootstrap-timepicker .dropdown-menu {
    width: 100%;
  }
}

.dx-tab-content {
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  padding: 15px;
  border-radius: 0px 0 4px 4px;
}
.select2-container {
  z-index: 999999;
}
.dx-orgchart-filter-container .select2-container {
  z-index: 9999 !important;
}
.select2-container .select2-selection--single {
  height: 34px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 32px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 32px;
  line-height: 32px;
}
.dx-form-field-line .btn {
  height: 34px;
}
.blink {
  animation: blinker 1s step-start infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.inbox .dx-meeting-table tbody tr > td.dx-meeting-table-cell,
.inbox .dx-meeting-table tbody tr > th.dx-meeting-table-cell,
.inbox .dx-meeting-table tbody > tr > td.dx-meeting-table-cell,
.inbox .dx-meeting-table tbody > tr > th.dx-meeting-table-cell {
  cursor: default;
}
.inbox .dx-meeting-table tbody tr > td.dx-meeting-table-cell-grab,
.inbox .dx-meeting-table tbody tr > th.dx-meeting-table-cell-grab,
.inbox .dx-meeting-table tbody > tr > td.dx-meeting-table-cell-grab,
.inbox .dx-meeting-table tbody > tr > th.dx-meeting-table-cell-grab,
td.dx-meeting-table-cell-grab,
th.dx-meeting-table-cell-grab {
  cursor: grab;
}

#dx-filesystem-panel {
  display: -ms-flexbox;
  display: flex;
  padding: .5em;
  background-color: white;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex: 1;
  flex: 1;
}
#dx-filesystem-panel .dx-filesystem-left-panel {
  padding: 1em;
  margin: 10px;
  border: 1px solid #e7ecf1;
  border-radius: 4px;
  /*display: flex;*/
  width: 80%;
  min-height: 300px;
}
#dx-filesystem-panel .dx-filesystem-left-panel tr {
  color: #777;
  font-size: 13px;
}
#dx-filesystem-panel .dx-filesystem-left-panel .table th {
  background: #eef4f7;
  border-bottom: solid 5px #fff;
}
#dx-filesystem-panel .dx-filesystem-table .table > tbody > tr > td {
  border: none !important;
}
#dx-filesystem-panel .dx-filesystem-right-panel {
  -ms-flex-pack: center;
  justify-content: center;
  padding: 1em;
  margin: 10px;
  border: 1px solid #e7ecf1;
  border-radius: 4px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 1;
  flex: 1;
  min-height: 500px;
}
#dx-filesystem-panel .align {
  padding: 1em;
}
#dx-filesystem-panel #drop-area {
  border: 4px dashed gray;
  border-radius: 20px;
  font-family: sans-serif;
  text-align: center;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1;
  flex: 1;
}
#dx-filesystem-panel #drop-area.highlight {
  border-color: darkblue;
}
#dx-filesystem-panel .upload {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
#dx-filesystem-panel #gallery {
  margin-top: 10px;
}
#dx-filesystem-panel #gallery img {
  width: 150px;
  margin-bottom: 10px;
  margin-right: 10px;
  vertical-align: middle;
}
#dx-filesystem-panel .file-upload-browse {
  display: inline-block;
  cursor: pointer;
}
#dx-filesystem-panel .remove-row {
  border: 1px solid #222b5d !important;
  background-color: #374495 !important;
  color: white;
  padding: 6px 12px;
  cursor: pointer;
}
#dx-filesystem-panel .remove-row:hover {
  background-color: #3f3f3f;
}
#dx-filesystem-panel .remove-row:active {
  position: relative;
  top: 1px;
}
#dx-filesystem-panel .dx-filesystem-file-input {
  display: none;
}
#dx-filesystem-panel a .backFolder:link,
#dx-filesystem-panel .backFolder:hover,
#dx-filesystem-panel .backFolder:visited,
#dx-filesystem-panel .backFolder:active {
  color: white;
  text-decoration: none;
}

/*# sourceMappingURL=elix_view_less.css.map */
